<template>
  <section class="m-guest-address m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img src="@/assets/login/logo@2x.png" alt="img" class="logo">
      <p class="tips">请输入您的收货信息</p>
    </div>

    
    <div class="main">

      <div class="guest-cell">
        <div class="label">First Name</div>
        <input v-model="firstname" type="text" placeholder="请输入 frist name">
      </div>
      <div class="guest-cell">
        <div class="label">Last Name</div>
        <input v-model="lastname" type="text" placeholder="请输入 last name">
      </div>
      <div class="guest-cell">
        <div class="label">Tel</div>
        <input v-model="phone" type="text" placeholder="请输入手机号">
        <span @click="telPicker = true">+{{phoneType}}</span>
      </div>
      <div class="guest-cell">
        <div class="label">Email</div>
        <input v-model="email" type="text" placeholder="请输入 Email">
      </div>
      <div class="guest-cell" @click="showPicker = true">
        <div class="label">所在地区</div>
        <input v-model="city" readonly type="text" placeholder="请选择所在地区">
        <img src="@/assets/public/icon-fanhui@2x.png" alt="back">
      </div>
      <div class="guest-cell-address">
        <div class="label">详细地址</div>
        <div class="address">
          <p>如果您住在公寓 请务必填写unit#</p>
          <van-field v-model="address" rows="1" autosize type="textarea" placeholder="请输入详细地址"/>
          <!-- <input v-model="address" type="text" placeholder="请输入详细地址"> -->
        </div>
        
      </div>
      <div class="guest-cell">
        <div class="label">邮编</div>
        <input v-model="code" type="text" placeholder="请输入邮编">
      </div>
      
      <div class="footer ">
        <van-button round block type="info" native-type="submit" color="linear-gradient(90deg,rgba(45,119,224,1) 0%,rgba(36,95,179,1) 100%)" class="wave"> 确认 </van-button>
      </div>


    </div>
     

    <!-- 选择区号 -->
    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="取消" close-on-click-action @cancel="telPicker = false" @select="handlePhonetype" />
     <!-- 选择地址 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar title="选择城市" :columns="columns" @confirm="handleCity" @cancel="showPicker = false" />
    </van-popup>



  </section>
</template>

<script>


export default {
  name:'Login',

  data(){
    return {
      firstname:'',
      lastname:'',
      phone:'',
      phoneType:1,
      email:'',
      city:'',
      address:'',
      code:'',
      actions: [{ name: '1' }, { name: '86' },],
      columns: [
        {
          text: '浙江',
          children: [
            {
              text: '杭州',
              children: [{ text: '西湖区' }, { text: '余杭区' }],
            },
            {
              text: '温州',
              children: [{ text: '鹿城区' }, { text: '瓯海区' }],
            },
          ],
        },
        {
          text: '福建',
          children: [
            {
              text: '福州',
              children: [{ text: '鼓楼区' }, { text: '台江区' }],
            },
            {
              text: '厦门',
              children: [{ text: '思明区' }, { text: '海沧区' }],
            },
          ],
        },
      ],
      telPicker:false,
      showPicker: false,
    }
  },

  mounted(){
    // this.$refs.home.

  },

  methods:{

    // 提交
    onSubmit(){
      console.log('submit')
    },

    // 选择区号
    handlePhonetype(it){
      this.phoneType = it.name
      this.telPicker = false
    },

    // 选择城市 确认
    handleCity(value){
      this.city = value.join('-')
      this.showPicker = false
    }


   
  }
}
</script>

<style lang="less" scoped>
@import './guest.less';
</style>